import { Button } from "../../ui/Buttons"

export function Finished() {
    return (
        <div class="empty-cart-view">
            <div class="empty-cart-view-title">
                Спасибо за заказ!
            </div>
            <div class="empty-cart-view-text">
                Мы свяжемся с вами в ближайшее время
            </div>
            <Button to="/">На главную</Button>
        </div>
    )
}