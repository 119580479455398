import { Button } from "../../ui/Buttons"
import styled from "styled-components"
import "./Header.css"
import { Categories } from "../Categories/Categories"
import { useEffect, useState } from "react"
import { CallButton, CartButton } from "../NavBar/NavBar"
import { Item } from "../Item/Item"
import axios from "axios"
import { useLocation } from "react-router-dom"

const LogoButton = styled(Button)`
    background-color: transparent;
    outline: none;
    font-size: 24pt;
    @media(hover: hover) {
        &:hover {
            background-color: transparent;
        }
    };
    @media(max-width: 600px) {
        font-size: 16pt;
    }
`

const SearchContainer = styled.div`
    display: flex;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    background-color: white;
    align-items: center;
    padding-left: 10px;

`

const SearchField = styled.input`
    display: flex;
    height: 100%;
    border: none;
    background-color: clear;
    padding-left: 10px;
    outline: none;
    margin: 0px;
`

const CancelButtonContainer = styled.div`
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
`

export function Header() {
    const [res, setRes] = useState([])
    const [search, setSearch] = useState("")
    const location = useLocation()

    useEffect(() => {
        setRes([])
        setSearch("")
    }, [location])

    useEffect(() => {
        if (search !== "") {
            setRes([])
            axios.post("https://shopmobilaserver.ru/items/itemSearch", {"searchString": search}).then((response) => {
                setRes(response.data)
            })
        } else {
            setRes([])
        }
    }, [search])

    const handleCancel = () => {
        setRes([])
        setSearch("")
    }

    return (
        <>
        <div className="header-body">
            <div className="header-content">
                <LogoButton to="/">
                    <div className="header-logo">
                        <a className="header-logo-capital">S</a>hop
                        <a className="header-logo-capital">M</a>obila
                    </div>
                </LogoButton>
                <div className="header-desktop-buttons">
                    <div className="header-desktop-search">
                        <SearchContainer>
                            <i class="fa-solid fa-magnifying-glass"></i>
                            <SearchField placeholder="Поиск..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                        </SearchContainer>
                        {res.length !== 0 ?                     
                            <div className="desktop-search-results">
                                <div className="section-body">
                                    <CancelButtonContainer>
                                        <Button onClick={handleCancel}>Отмена</Button>
                                    </CancelButtonContainer>
                                    <div className="section-content-body">
                                        <div className="section-content">
                                            {res.map((item, key) => (
                                                <Item key={key} id={item["id"]}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div> : <></>
                        }
                    </div>
                    <CallButton/>
                    <CartButton/>
                </div>
                <div className="header-mobile-search">
                    <SearchContainer>
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <SearchField placeholder="Поиск..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                    </SearchContainer>
                    {res.length !== 0 ?                     
                        <div className="mobile-search-results">
                            <div className="section-body">
                                <CancelButtonContainer>
                                    <Button onClick={handleCancel}>Отмена</Button>
                                </CancelButtonContainer>
                                <div className="section-content-body">
                                    <div className="section-content">
                                        {res.map((item, key) => (
                                            <Item key={key} id={item["id"]}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> : <></>
                    }
                </div>
            </div>
        </div>
        <HeaderCorners/>
        </>
    )
}

export function HeaderCorners() {
    return (
        <>
            <div className="header-corners">
                <div className="header-corner-left"/>
                <div className="header-corner-right"/>
            </div>
            <div className="after-header"/>
        </>
    )
}