import "./order.css"
import { Button, ButtonPrimary } from "../../ui/Buttons"
import styled from "styled-components"
import { useState, useEffect } from "react"
import axios from "axios"

const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`

const WideButtonSelected = styled(WideButton)`
    color: #c9333a;
    outline: 1px solid #c9333a;
    background-color: #f6f8fa;
    border: 1px solid #c9333a;
`

const ProceedButton = styled(ButtonPrimary)`
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
`

const SectionButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #c9333a;
    border: 1px solid #c9333a;
    color: white;
    margin-top: 10px;
    &:hover {
        background-color: #c21724;
        color: white;
    }
`

export function Order() {
    const [ deliveryType, setDeliveryType ] = useState("delivery") 
    const [ cart, setCart ] = useState([])

    useEffect(() => {
        console.log("asas")
    }, [])

    const [ total, setTotal ] = useState("0")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        var jsonCart = localStorage.getItem("cart")
        var cartObject = JSON.parse(jsonCart)
        if (cartObject === null) {
            cartObject = []
        }
        setCart(cartObject)
        handleTotal()
    }, [])

    const handleTotal = async () => {
        var cartObject = JSON.parse(localStorage.getItem("cart"))
        if (cartObject === null) {
            cartObject = []
       }
        var totalPrice = 0
        for (var i = 0; i < cartObject.length; i++) {
            await axios.post("https://shopmobilaserver.ru/items/getPrice", {"id": cartObject[i]["id"]}).then((response) => {
                totalPrice = totalPrice + (Number(response.data["response"].replace(" ", "")) * cartObject[i]["amount"])
            })
        }
        setTotal(String(totalPrice))
    }

    return (
        <div class="order-view-body">
            <div class="order-view-content">
                <ProceedSection total={total}/>
                <div class="delivery-options">
                    { deliveryType === "delivery" ?
                    <WideButtonSelected>
                        Доставка
                    </WideButtonSelected> 
                    :
                    <WideButton onClick={() => {setDeliveryType("delivery")}}>
                        Доставка
                    </WideButton>
                    }
                    <span/>
                    { deliveryType === "pickup" ?
                    <WideButtonSelected>
                        Самовывоз
                    </WideButtonSelected> 
                    :
                    <WideButton onClick={() => {setDeliveryType("pickup")}}>
                        Самовывоз
                    </WideButton>
                    }
                </div>
                { deliveryType === "delivery" ?
                    <OrderWithDelivery/>
                    :
                    <OrderWithPickup/>
                }
            </div>
            
        </div>
    )
}

function OrderWithDelivery() {
    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ city, setCity ] = useState("")
    const [ street, setStreet ] = useState("")
    const [ entrance, setEntrance ] = useState("")
    const [ floor, setFloor ] = useState("")
    const [ flat, setFlat ] = useState("")
    const [ time, setTime ] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    const placeOrder = () => {
        const orderJson = {
            "type": "delivery",
            "address": {
                "city": city,
                "street": street,
                "entrance": entrance,
                "floor": floor,
                "flat": flat
            },
            "name": name,
            "phone": phone,
            "time": time,
            "items": JSON.parse(localStorage.getItem("cart")),
            "payment": localStorage.getItem("payment"),
            "total": localStorage.getItem("totalPrice") 
        }
        
        axios.post("https://shopmobilaserver.ru/items/place_order", orderJson).then((response) => {
            console.log(response.data)
            window.location.href = "/cart/order/finished"
        })

        localStorage.removeItem("cart")
        localStorage.removeItem("orderItems")
    }

    return (
        <>
            <div>
                <input placeholder="Имя" value={name} onChange={(e) => {setName(e.target.value)}}/>
                <div class="mixed-input">
                    +7 <input placeholder="Номер телефона" type="tel" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                </div>
                <input placeholder="Город" value={city} onChange={(e) => {setCity(e.target.value)}}/>
                <input placeholder="Улица" value={street} onChange={(e) => {setStreet(e.target.value)}}/>
                <input placeholder="Подъезд" value={entrance} onChange={(e) => {setEntrance(e.target.value)}}/>
                <input placeholder="Этаж" value={floor} onChange={(e) => {setFloor(e.target.value)}}/>
                <input placeholder="Квартира" value={flat} onChange={(e) => {setFlat(e.target.value)}}/>
                <input placeholder="Удобное для Вас время" value={time} onChange={(e) => {setTime(e.target.value)}}/>
            </div>
            <div class="delivery-method">
                Мы свяжемся с Вами для уточнения способа доставки.
            </div>
            { loading ? <ProceedButton>Обработка заказа...</ProceedButton> : 
                <ProceedButton onClick={placeOrder}>Оформить</ProceedButton>
            }
        </>
    )
}

function OrderWithPickup() {
    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("") 
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
        for (var i = 0; i < phone.length; i++) {
            if (!(phone[i] in allowedPhoneValues)) {
                setPhone(phone.replace(phone[i], ""))
            }
        }

        if (phone.length > 10) {
            setPhone(phone.slice(0, -1))
        }
    }, [phone])

    const placeOrder = () => {
        const orderJson = {
            "type": "pickup",
            "name": name,
            "phone": phone,
            "items": JSON.parse(localStorage.getItem("orderItems")),
            "payment": localStorage.getItem("payment"),
            "total": localStorage.getItem("totalPrice") 
        }
        
        if (localStorage.getItem("orderItems") !== null) {
            axios.post("https://shopmobilaserver.ru/items/place_order", orderJson).then((response) => {
                console.log(response.data)
                window.location.href = "/cart/order/finished"
            })

            localStorage.removeItem("cart")
            localStorage.removeItem("orderItems")
        }
    }

    return (
        <>
            <div>
                <input placeholder="Имя" value={name} onChange={(e) => {setName(e.target.value)}}/>
                <div class="mixed-input">
                    +7 <input placeholder="Номер телефона" type="tel" class="mixed-field-left" value={phone} onChange={(e) => {setPhone(e.target.value)}}/>
                </div>
                <div class="where-pickup">
                    Самовывоз по адресу г. Москва, ул. Барклая, д. 8 ТЦ «Горбушка», 2 эт, 267 пав. (м. Багратионовская)
                </div>
                <div class="pickup-time">
                    
                </div>
            </div>
            { loading ? <ProceedButton>Обработка заказа...</ProceedButton> : 
                <ProceedButton onClick={placeOrder}>Оформить</ProceedButton>
            }
        </>
    )
}

function useLocalStorageCart() {
    const [cart, setCart] = useState(() => {
        const storedCart = localStorage.getItem("cart");
        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const storedCart = localStorage.getItem("cart");
            setCart(storedCart ? JSON.parse(storedCart) : []);
        };

        window.addEventListener("storage", handleStorageChange);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return [cart, setCart];
}

function ProceedSection({ total }) {
    const [method, setMethod] = useState("cash");
    const [percentageLabel, setPercentageLabel] = useState("");
    const [cart] = useLocalStorageCart();
    const [cartLength, setCartLength] = useState(localStorage.getItem("cart").length)

    useEffect(() => {
        localStorage.setItem("payment", method);
        updatePercentageLabel(method);
    }, [method]);

    useEffect(() => {
        localStorage.setItem("payment", method);
        localStorage.setItem("totalPrice", total);
        updatePercentageLabel(method);
    }, []);

    useEffect(() => {
        calculateTotalWithMethod(method);     
        setCartLength(localStorage.getItem("cart").length)
        console.log(cart.reduce((acc, item) => acc + item.amount, 0))
    }, [localStorage.getItem("cart")]);

    useEffect(() => {
        const paymentData = localStorage.getItem("payment");
        
        // Check if paymentData is not null and is a string
        if (paymentData && typeof paymentData === "string") {
          setMethod(paymentData);
        } else {
          console.log("No valid payment data found in localStorage.");
        }
      }, []);

    const updatePercentageLabel = (method) => {
        switch (method) {
            case "qr":
                setPercentageLabel("+6% к цене");
                break;
            case "card":
                setPercentageLabel("+7% к цене");
                break;
            case "invoice":
                const itemCount = cart.reduce((acc, item) => acc + item.amount, 0);
                setPercentageLabel(`+${itemCount < 3 ? "10%" : "15%"} к цене`);
                break;
            case "credit":
                setPercentageLabel("+10% к цене +% банка");
                break;
            case "installment6":
                setPercentageLabel("+20% к цене");
                break;
            case "installment12":
                setPercentageLabel("+30% к цене");
                break;
            default:
                setPercentageLabel(""); // No adjustment for cash
        }
    };

    const calculateTotalWithMethod = (amount) => {
        const baseAmount = Number(amount.replace(" ", ""));
        switch (method) {
            case "qr":
                localStorage.setItem("totalPrice", (baseAmount * 1.06).toFixed(2))
                return (baseAmount * 1.06).toFixed(2);
            case "card":
                localStorage.setItem("totalPrice", (baseAmount * 1.07).toFixed(2))
                return (baseAmount * 1.07).toFixed(2);
            case "invoice":
                const itemCount = cart.reduce((acc, item) => acc + item.amount, 0);
                const rate = itemCount < 3 ? 1.10 : 1.15;
                localStorage.setItem("totalPrice", (baseAmount * rate).toFixed(2))
                return (baseAmount * rate).toFixed(2);
            case "credit":
                localStorage.setItem("totalPrice", (baseAmount * 1.10).toFixed(2))
                return (baseAmount * 1.10).toFixed(2);
            case "installment6":
                localStorage.setItem("totalPrice", (baseAmount * 1.20).toFixed(2))
                return (baseAmount * 1.20).toFixed(2);
            case "installment12":
                localStorage.setItem("totalPrice", (baseAmount * 1.30).toFixed(2))
                return (baseAmount * 1.30).toFixed(2);
            default:
                localStorage.setItem("totalPrice", baseAmount.toFixed(2))
                return baseAmount.toFixed(2);
        }
    };

    const handleProceed = async () => {
        const cartObject = JSON.parse(localStorage.getItem("cart"));
        const allItems = [];
        let totalPrice = 0;
        let totalBonus = 0;

        for (let i = 0; i < cartObject.length; i++) {
            await axios.post("https://shopmobilaserver.ru/items/getPrice", { id: cartObject[i].id }).then((response) => {
                const price = response.data.response;
                const bonus = parseInt(Number(price.replace(" ", "")) / 300);

                const thisJson = {
                    id: cartObject[i].id,
                    price: price,
                    amount: cartObject[i].amount,
                    bonus: String(bonus),
                };

                totalPrice += Number(price.replace(" ", ""));
                totalBonus += bonus;

                allItems.push(thisJson);
            });
        }

        localStorage.setItem("orderItems", JSON.stringify(allItems));
        window.location.href = "/cart/order";
    };

    return (
        <div className="item-view-section-container">
            <div className="item-view-price-row">
                <>{calculateTotalWithMethod(total)} ₽</>
            </div>
            <div className="item-view-card-price">
                <div>{percentageLabel}</div>
            </div>
            <div className="payment-method-selector">
                <div className="button-grid">
                    {method === "cash" ? (
                        <WideButtonSelected>Наличные</WideButtonSelected>
                    ) : (
                        <WideButton onClick={() => setMethod("cash")}>Наличные</WideButton>
                    )}
                    {method === "qr" ? (
                        <WideButtonSelected>QR код</WideButtonSelected>
                    ) : (
                        <WideButton onClick={() => setMethod("qr")}>QR код</WideButton>
                    )}
                    {method === "card" ? (
                        <WideButtonSelected>Карта</WideButtonSelected>
                    ) : (
                        <WideButton onClick={() => setMethod("card")}>Карта</WideButton>
                    )}
                    {method === "invoice" ? (
                        <WideButtonSelected>Счет для ЮР лиц</WideButtonSelected>
                    ) : (
                        <WideButton onClick={() => setMethod("invoice")}>Счет для ЮР лиц</WideButton>
                    )}
                </div>
                <div className="credit-payment-options">
                    <div className="credit-payment-title">
                        Кредит/рассрочка
                    </div>
                    <div className="button-grid">
                        {method === "credit" ? (
                            <WideButtonSelected>Кредит</WideButtonSelected>
                        ) : (
                            <WideButton onClick={() => setMethod("credit")}>Кредит</WideButton>
                        )}
                        {method === "installment6" ? (
                            <WideButtonSelected>Рассрочка 6 мес.</WideButtonSelected>
                        ) : (
                            <WideButton onClick={() => setMethod("installment6")}>Рассрочка 6 мес.</WideButton>
                        )}
                        {method === "installment12" ? (
                            <WideButtonSelected>Рассрочка 12 мес.</WideButtonSelected>
                        ) : (
                            <WideButton onClick={() => setMethod("installment12")}>Рассрочка 12 мес.</WideButton>
                        )}
                    </div>
                </div>
            </div>
            <div className="transfer-warning">
                { method === "cash" ? <>Оплата переводом принимается только при отправке через СДЭК.</> : <></> }
            </div>
        </div>
    );
}
