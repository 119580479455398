import { ButtonPrimary, Button, ButtonInline, ButtonHolder } from "../ui/Buttons";
import { Header } from "../components/Header/Header";
import { NavBar } from "../components/NavBar/NavBar";
import { Section } from "../ui/Section";
import { Banners } from "../components/Banners/Banners";
import { IphoneSection, AirpodsSection, IpadSection } from "../components/Sections/Sections";

import { BlankSpace } from "../ui/BlankSpace";

import { Categories } from "../components/Categories/Categories";
import { HeaderCorners } from "../components/Header/Header";

export function Main() {
    return (
        <>
            <Categories/>
            <HeaderCorners/>
            <Section>
                <Banners/>
            </Section>
            <IphoneSection/>  
            <Section>
                <AirpodsSection/>
            </Section>
            <IpadSection/>  
        </>
    )
}