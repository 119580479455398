import { Link } from "react-router-dom"
import "./NavBar.css"
import { Categories } from "../Categories/Categories"
import { useEffect, useRef } from "react"

export function NavBar () {

    const handleMenuButton = () => {
        console.log("a")
        document.getElementById("slide-menu").style.bottom = "0"
    }

    return (
        <>
        <div className="navbar-corners">
            <div className="navbar-corner-left"/>
            <div className="navbar-corner-right"/>
        </div>
        <div className="navbar-body">
            <div className="navbar-content">
                    <CallButton/>
                    <MenuButton/>
                    <CartButton/>
            </div>
        </div>
        <SlideMenu/>
        </>
    )
}

export function CallButton() {
    return (
        <a className="navbar-button" href="tel:+79264423636">
            <div><i class="fa-solid fa-phone"></i></div>
            <div className="navbar-button-label">Звонок</div>
        </a>
    )
}

function MenuButton() {

    const handleClick = () => {
        document.getElementById("slide-menu").style.bottom = "0px"
        document.getElementById("slide-menu").style.pointerEvents = "auto"
        document.getElementById("menu-open-button").style.scale = "0.0"
    }

    return (
        <div className="navbar-button navbar-menu-button" id="menu-open-button" onClick={handleClick}>
            <div><i class="fa-solid fa-bars"></i></div>
        </div>
    )
}

export function CartButton() {
    return (
        <Link to="/cart" className="navbar-button">
            <div><i class="fa-solid fa-cart-shopping"></i></div>
            <div className="navbar-button-label">Корзина</div>
        </Link>
    )
}



function SlideMenu() {
    const slideMenuRef = useRef(null);

    const handleClose = () => {
        document.getElementById("slide-menu").style.bottom = "-400px";
        document.getElementById("slide-menu").style.pointerEvents = "none";
        document.getElementById("menu-open-button").style.scale = "1.2";
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (slideMenuRef.current && !slideMenuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div id="slide-menu" className="slideMenu" ref={slideMenuRef}>
            <div className="slideMenuHeader">
                <div className="navbar-content" onClick={handleClose}>
                    <MenuButton />
                </div>
            </div>
            <div className="menu-item-list">
                <div onClick={() => {window.location.href = "/iphone/all"}} className="menu-item">
                    <i className="fa-solid fa-chevron-right"></i><a className="menu-item-capital">i</a>Phone
                </div>
                <div onClick={() => {window.location.href = "/airpods/all"}} className="menu-item">
                    <i className="fa-solid fa-chevron-right"></i><a className="menu-item-capital">A</a>irPods
                </div>
                <div onClick={() => {window.location.href = "/ipad/all"}} className="menu-item">
                    <i className="fa-solid fa-chevron-right"></i><a className="menu-item-capital">i</a>Pad
                </div>
                <div onClick={() => {window.location.href = "/watch/all"}} className="menu-item">
                    <i className="fa-solid fa-chevron-right"></i><a className="menu-item-capital">W</a>atch
                </div>
                <div onClick={() => {window.location.href = "/mac/all"}} className="menu-item">
                    <i className="fa-solid fa-chevron-right"></i><a className="menu-item-capital">M</a>ac
                </div>
            </div>
        </div>
    );
}
