import { Link, useParams } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import { HeaderCorners } from "../../components/Header/Header"

import "../../components/Sections/Sections.css"

import { SectionModel } from "../../components/Sections/Sections"
import watchbanner from "../../components/Sections/banners/watch.png"
import watchseries9 from "../../components/Sections/images/Watch/Series 9.png"
import watchseries8 from "../../components/Sections/images/Watch/Series 8.png"
import watchSE from "../../components/Sections/images/Watch/SE.png"

import { Item } from "../../components/Item/Item"
import { BackButton } from "../../ui/Buttons"

export function WatchModels() {
    const [ids, setIds] = useState([])
    const {model} = useParams()

    useEffect(() => {
        setIds([])
        if (model === "all") {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": "Watch"}).then((response) => {
                setIds(response.data)
            })
        } else {
            let getDevice = "Watch " + model
            axios.post("https://shopmobilaserver.ru/items/getModel", {"model": getDevice}).then((response) => {
                setIds(response.data)
            })
        }
    }, [model])

    return (
        <div className="section-body">
            <div><BackButton/></div>
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={watchseries9} title={"Watch Ultra"} to={"/watch/Ultra"}/>
                    <SectionModel image={watchseries9} title={"Watch Series 9"} to={"/watch/Series 9"}/>
                    <SectionModel image={watchseries8} title={"Watch Series 8"} to={"/watch/Series 8"}/>
                    <SectionModel image={watchSE} title={"Watch SE"} to={"/watch/SE"}/>
                </div>
            </div>
            {model === "all" ? <></> : 
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/watch/all">
                Все Watch
                </Link>
            </div>
            }
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((item, key) => (
                        <Item key={key} id={item["id"]}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

//<div className="section-body-banner">
//<img src={watchbanner}/>
//</div>