import { Link, useParams } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import { HeaderCorners } from "../../components/Header/Header"

import "../../components/Sections/Sections.css"

import { SectionModel } from "../../components/Sections/Sections"
import iphonebanner from "../../components/Sections/banners/iphone.png"
import iphone16promax from "../../components/Sections/images/iPhone/16 Pro Max.png"
import iphone16pro from "../../components/Sections/images/iPhone/16 Pro.png"
import iphone16plus from "../../components/Sections/images/iPhone/16 Plus.png"
import iphone16 from "../../components/Sections/images/iPhone/16.png"
import iphone15promax from "../../components/Sections/images/iPhone/15 Pro Max.png"
import iphone15pro from "../../components/Sections/images/iPhone/15 Pro.png"
import iphone15plus from "../../components/Sections/images/iPhone/15 Plus.png"
import iphone15 from "../../components/Sections/images/iPhone/15.png"
import iphone14 from "../../components/Sections/images/iPhone/14.png"
import iphone13 from "../../components/Sections/images/iPhone/13.png"
import iphone12 from "../../components/Sections/images/iPhone/12.png"
import iphone11 from "../../components/Sections/images/iPhone/11.png"

import { Item } from "../../components/Item/Item"
import { BackButton } from "../../ui/Buttons"

export function IphoneModels() {
    const [ids, setIds] = useState([])
    const {model} = useParams()

    useEffect(() => {
        setIds([])
        if (model === "all") {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": "iPhone"}).then((response) => {
                setIds(response.data)
            })
        } else {
            let getModel = "iPhone " + model
            axios.post("https://shopmobilaserver.ru/items/getModel", {"model": getModel}).then((response) => {
                setIds(response.data)
            })
        }
    }, [model])

    return (
        <div className="section-body">
            <div><BackButton/></div>
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={iphone16promax} title={"iPhone 16 Pro Max"} to={"/iphone/16 Pro Max"}/>
                    <SectionModel image={iphone16pro} title={"iPhone 16 Pro"} to={"/iphone/16 Pro"}/>
                    <SectionModel image={iphone16plus} title={"iPhone 16 Plus"} to={"/iphone/16 Plus"}/>
                    <SectionModel image={iphone16} title={"iPhone 16"} to={"/iphone/16"}/>
                    <SectionModel image={iphone15promax} title={"iPhone 15 Pro Max"} to={"/iphone/15 Pro Max"}/>
                    <SectionModel image={iphone15pro} title={"iPhone 15 Pro"} to={"/iphone/15 Pro"}/>
                    <SectionModel image={iphone15plus} title={"iPhone 15 Plus"} to={"/iphone/15 Plus"}/>
                    <SectionModel image={iphone15} title={"iPhone 15"} to={"/iphone/15"}/>
                    <SectionModel image={iphone14} title={"iPhone 14"} to={"/iphone/14"}/>
                    <SectionModel image={iphone13} title={"iPhone 13"} to={"/iphone/13"}/>
                    <SectionModel image={iphone12} title={"iPhone 12"} to={"/iphone/12"}/>
                    <SectionModel image={iphone11} title={"iPhone 11"} to={"/iphone/11"}/>
                </div>
            </div>            
            {model === "all" ? <></> : 
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/iphone/all">
                    Все iPhone
                </Link>
            </div>
            }
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((item, key) => (
                        <Item key={key} id={item["id"]}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

//<div className="section-body-banner">
//<img src={iphonebanner}/>
//</div>