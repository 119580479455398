import "./Footer.css"
import { Link } from "react-router-dom"

export function Footer() {
    return (
        <div className="footer-body">
            <div className="footer-content">
                <div className="footer-section footer-section-upper">
                    <div className="header-logo">
                        <div className="header-logo-capital">S</div>hop
                        <div className="header-logo-capital">M</div>obila
                    </div>
                    <div className="about-footer-section">
                        Интернет-магазин — сайт, торгующий товарами посредством сети Интернет. Позволяет пользователям онлайн, в своём браузере или через мобильное приложение, сформировать заказ на покупку, выбрать способ оплаты и доставки заказа, оплатить заказ.
                    </div>
                </div>
                <div className="footer-section footer-section-upper">
                    <div className="footer-section-title">
                        Контакты
                    </div>
                    <div className="footer-title-divider"/>
                    <div className="footer-section-contact">
                        <i class="fa-solid fa-phone"></i><a className="footer-contact-space"/>8-926-442-36-36
                    </div>
                    <div className="footer-section-contact">
                        <i class="fa-solid fa-envelope"></i><a className="footer-contact-space"/> support@shopmobila.ru
                    </div>
                    <div className="footer-section-contact">
                        <i class="fa-regular fa-clock"></i><a className="footer-contact-space"/> ЕЖЕДНЕВНО С 11-00 ДО 20-00
                    </div>
                    <div className="footer-section-contact">
                        <i class="fa-solid fa-location-dot"></i><a className="footer-contact-space"/> Москва, ул. Барклая, д. 8 ТЦ «Горбушка», 2 эт, 267 пав. (м. Багратионовская)
                    </div>
                </div>
                <div className="footer-section">
                    <div className="footer-section-title">
                        Информация
                    </div>
                    <div className="footer-title-divider"/>
                    <Link className="info-link" to="/warranty">
                        <div className="footer-section-contact">
                            <i class="fa-solid fa-chevron-right"></i><a className="footer-contact-space"/> Гарантия
                        </div>
                    </Link>
                    <Link className="info-link" to="/aboutus">
                        <div className="footer-section-contact">
                            <i class="fa-solid fa-chevron-right"></i><a className="footer-contact-space"/> О нас
                        </div>
                    </Link>
                    <Link className="info-link" to="/delivery">
                        <div className="footer-section-contact">
                            <i class="fa-solid fa-chevron-right"></i><a className="footer-contact-space"/> Доставка
                        </div>
                    </Link>
                    <Link className="info-link" to="/contacts">
                        <div className="footer-section-contact">
                            <i class="fa-solid fa-chevron-right"></i><a className="footer-contact-space"/> Контакты
                        </div>
                    </Link>
                </div>
                <div className="footer-section">
                    <div className="footer-section-title">
                        Карта
                    </div>
                    <div className="footer-title-divider"/>
                    <iframe src="https://yandex.ru/map-widget/v1/?lang=ru_RU&amp;scroll=true&amp;source=constructor-api&amp;um=constructor%3Aaa9bb24719b21d9aa537ceaf4597b317e23c3fc29782bef78e8d94f9067130c0" frameborder="0" allowfullscreen="true" width="100%" height="300px" style={{display: "block"}}></iframe>
                </div>
            </div>
        </div>
    )
}