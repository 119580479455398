const BASE_URL = 'https://shopmobilaserver.ru'; // Adjust based on your server URL

// Fetch all items
export async function fetchItems() {
    const response = await fetch("https://shopmobilaserver.ru/items/getAllItems");
    if (!response.ok) {
        throw new Error('Failed to fetch items');
    }
    return response.json();
}

// Update an item
export async function updateItem(item) {
    const response = await fetch(`${BASE_URL}/items/updateItem`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
    });
    return response.ok;
}

// Delete an item
export async function deleteItem(id) {
    const response = await fetch(`${BASE_URL}/items/deleteItem`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
    });
    return response.ok;
}
