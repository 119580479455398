import { Link, useParams } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import { HeaderCorners } from "../../components/Header/Header"

import "../../components/Sections/Sections.css"

import { SectionModel } from "../../components/Sections/Sections"
import ipadbanner from "../../components/Sections/banners/ipad.png"
import ipadpro from "../../components/Sections/images/iPad/Pro.png"
import ipadair from "../../components/Sections/images/iPad/Air.png"
import ipad9 from "../../components/Sections/images/iPad/9.png"
import ipad10 from "../../components/Sections/images/iPad/10.png"
import ipadairm2 from "../../components/Sections/images/iPad/Air M2.png"
import prom1 from "../../components/Sections/images/iPad/Pro M1.png"
import prom2 from "../../components/Sections/images/iPad/Pro M2.png"

import { Item } from "../../components/Item/Item"
import { BackButton } from "../../ui/Buttons"

export function IpadModels() {
    const [ids, setIds] = useState([])
    const {model} = useParams()

    useEffect(() => {
        setIds([])
        if (model === "all") {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": "iPad"}).then((response) => {
                setIds(response.data)
            })
        } else {
            let getDevice = "iPad " + model
            axios.post("https://shopmobilaserver.ru/items/getModel", {"model": getDevice}).then((response) => {
                setIds(response.data)
            })
        }
    }, [model])

    return (
        <div className="section-body">
            <div><BackButton/></div>
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={ipadpro} title={"iPad Pro 13 M4"} to={"/ipad/Pro 13 M4"}/>
                    <SectionModel image={ipadpro} title={"iPad Pro 11 M4"} to={"/ipad/Pro 11 M4"}/>
                    <SectionModel image={prom2} title={"iPad Pro 12.9 M2"} to={"/ipad/Pro 12.9 M2"}/>
                    <SectionModel image={prom2} title={"iPad Pro 11 M2"} to={"/ipad/Pro 11 M2"}/>
                    <SectionModel image={prom1} title={"iPad Pro 12.9 M1"} to={"/ipad/Pro 12.9 M1"}/>
                    <SectionModel image={prom1} title={"iPad Pro 11 M1"} to={"/ipad/Pro 11 M1"}/>
                    <SectionModel image={ipadairm2} title={"iPad Air 13 M2"} to={"/ipad/Air 13 M2"}/>
                    <SectionModel image={ipadairm2} title={"iPad Air 11 M2"} to={"/ipad/Air 11 M2"}/>
                    <SectionModel image={ipadair} title={"iPad Air 10.9 M1"} to={"/ipad/Air 10.9 M1"}/>
                    <SectionModel image={ipad10} title={"iPad 10"} to={"/ipad/10"}/>
                    <SectionModel image={ipad9} title={"iPad 9"} to={"/ipad/9"}/>
                </div>
            </div>
            {model === "all" ? <></> : 
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/ipad/all">
                Все iPad
                </Link>
            </div>
            }
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((item, key) => (
                        <Item key={key} id={item["id"]}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

//<div className="section-body-banner">
//<img src={ipadbanner}/>
//</div>