import "./info.css"

export function Delivery() {
    return (
        <div className="info-body">
            <div className="info-content">
                <div className="info-title">Доставка</div>
                Информация о доставке
                <br/><br/>
                Доставка в пределах мкад 700р оформление до 16:00 
                <br/><br/>
                после 16:00 от 800р , за мкад каждый км +50р 
                <br/><br/>
                Доставка к определенному времени от 800р 
                <br/><br/>
                Доставка товаров по России осуществляется курьерской службой СДЭК и возможна только после 100% оплаты заказа и доставки. Стоимость доставки рассчитывается при оформлении заказа. Подробности уточняйте у наших операторов.
            </div>
        </div>
    )
}