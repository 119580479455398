import "./Categories.css"

import iphone from "./images/iphone.png"
import airpods from "./images/airpods.png"
import ipad from "./images/ipad.png"
import mac from "./images/mac.png"
import watch from "./images/watch.png"

import { Link } from "react-router-dom"

export function Categories() {
    return (
        <div className="categories-body">
            <div className="categories-content">
                <Category image={iphone} title={"iPhone"} to={"/iphone/all"}/>
                <Category image={airpods} title={"AirPods"} to={"/airpods/all"}/>
                <Category image={watch} title={"Watch"} to={"/watch/all"}/>
                <Category image={ipad} title={"iPad"} to={"/ipad/all"}/>
                <Category image={mac} title={"Mac"} to={"/mac/all"}/>
            </div>
        </div>
    )
}

function Category({image, title, to}) {
    return (
        <Link className="category" to={to}>
            <img src={image}/>
            <div className="category-title">
                {title}
            </div>
        </Link>
    )
}