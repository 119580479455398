import "./info.css"

export function Warranty() {
    return (
        <div className="info-body">
            <div className="info-content">
                <div className="info-title">Гарантия</div>
                ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА НАШЕГО ИНТЕРНЕТ-МАГАЗИНА
                <br/><br/>
                Изделие обеспечивается бесплатным гарантийным обслуживанием на срок 12 (двенадцать) месяцев с даты продажи при отсутствии нарушений условий гарантии и бесплатного гарантийного обслуживания.
                <br/><br/>
                Бесплатное гарантийное обслуживание включает проведение бесплатного ремонта с заменой необходимых запасных частей.
                <br/><br/>
                1. Бесплатное сервисное обслуживание не распространяется на:
                <br/><br/>
                · аккумуляторные батареи, элементы питания, внешние блоки питания и зарядные устройства
                <br/>
                · соединительные кабели, антенны и переходники для них, устройства "хэндс - фри" (гарнитуры), носители информации различных типов (карты памяти, диски с программным обеспечением)
                <br/>
                · чехлы, шнурки для переноски, крепежные приспособления, документацию и иные аксессуары, прилагаемые к изделию
                <br/><br/>
                2. Бесплатное гарантийное обслуживание не производится:
                <br/><br/>
                · в случае неправильного или не полностью заполненного гарантийного талона и/или товарного чека, при отсутствии кассового чека, исправлений в товарном чеке, отсутствии подписей продавца и/или покупателя, несовпадений серийного номера с серийным номером, указанным в гарантийном талоне
                <br/>
                · в случае нарушения правил и условий эксплуатации, изложенных на сайте производителя и в гарантийных обязательствах
                <br/>
                · если изделие имеет следы попыток неквалифицированного ремонта
                <br/>
                · если дефект вызван изменением конструкции или схемы изделия, не предусмотренным изготовителем;
                <br/>
                · если дефект вызван несчастным случаем, действиями непреодолимой силы, действиями третьих лиц или действиями потребителя;
                <br/>
                · если повреждения вызваны попаданием внутрь изделия посторонних веществ, предметов, жидкостей, насекомых
                <br/>
                · если дефект вызван механическим повреждением изделия или аксессуаров;
                <br/>
                · если дефект вызван несоответствием стандарта телекоммуникационных, питающих, кабельных сетей и/или другими внешними факторами
                <br/>
                · если дефект вызван использованием нестандартных и/или некачественных аксессуаров, принадлежностей, носителей информации, элементов питания;
                <br/><br/>
                Продавец снимает с себя ответственность за возможный вред прямо или косвенно нанесенный проданным изделием людям домашним животным или имуществу. В случае если это произошло в результате не соблюдения правил эксплуатации, установки изделия, действий третьих лиц или обстоятельств непреодолимой силы.
                <br/><br/>
                Возврат либо обмен потребителем изделия на аналогичное может быть в срок не более 7 (семи) суток с даты продажи изделия.
                <br/><br/>
                3. Возврат/обмен изделия производится только после технической экспертизы и если соблюдены следующие условия:
                <br/><br/>
                · имеются документы, подтверждающие факт приобретения изделия в нашем магазине
                <br/>
                · потребителем сохранен товарный вид изделия
                <br/>
                · отсутствуют механические и химические повреждения корпуса (сколы лакокрасочного покрытия, потертости, царапины, трещины) внутренних схем, аксессуаров, упаковки
                <br/>
                · изделия и аксессуары не имеют следов попыток неквалифицированного ремонта
                <br/>
                · сохранены все элементы упаковки изделия, в том числе защитные пленки, защитные пакеты, сдерживающие шлейфы;
                <br/>
                · комплектность изделия и аксессуаров не нарушена
                <br/><br/>
                ГАРАНТИЙНЫЕ ОБЯЗАТЕЛЬСТВА ОТ ПРОИЗВОДИТЕЛЕЙ
                <br/><br/>
                На продукцию, купленную в нашем магазине, может распространятся гарантия от производителя, если это указанно на сайте в параметрах товара.
                Гарантия производителя составляет 1 (один) год с даты продажи товара.
            </div>
        </div>
    )
}