import { ButtonPrimary, Button, ButtonInline, ButtonHolder } from "./ui/Buttons";
import { Header } from "./components/Header/Header";
import { NavBar } from "./components/NavBar/NavBar";
import { Section } from "./ui/Section";
import { Banners } from "./components/Banners/Banners";
import { IphoneSection, AirpodsSection } from "./components/Sections/Sections";

import { BlankSpace } from "./ui/BlankSpace";
import { Main } from "./views/Main";
import { Routes, Route, Router } from "react-router-dom";
import { IphoneModels } from "./views/Models/IphoneModels";
import { AirPodsModels } from "./views/Models/AirpodsModels";
import { WatchModels } from "./views/Models/WatchModels";
import { IpadModels } from "./views/Models/IpadModels";
import { MacModels } from "./views/Models/MacModels";
import { ItemView } from "./views/item/itemView";
import { Cart } from "./views/cart/cart";
import { Order } from "./views/order/order";
import { Footer } from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { Warranty } from "./views/info/warranty";
import { AboutUs } from "./views/info/aboutus";
import { Delivery } from "./views/info/delivery";
import { Contacts } from "./views/info/contacts";
import { FastOrder } from "./views/order/fastOrder"; 
import { Finished } from "./views/order/finished";
import AdminPage from "./views/admin/admin";

function App() {
  return (
    <div className="app">
      <Header/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/iphone/:model" element={<IphoneModels/>}/>
        <Route path="/airpods/:model" element={<AirPodsModels/>}/>
        <Route path="/watch/:model" element={<WatchModels/>}/>
        <Route path="/ipad/:model" element={<IpadModels/>}/>
        <Route path="/mac/:model" element={<MacModels/>}/>
        <Route path="/item/:id" element={<ItemView/>}/>
        <Route path="/cart" element={<Cart/>}/>
        <Route path="/cart/order" element={<Order/>}/>
        <Route path="/cart/order/finished" element={<Finished/>}/>
        <Route path="/fastOrder" element={<FastOrder/>}/>
        <Route path="/warranty" element={<Warranty/>}/>
        <Route path="/aboutus" element={<AboutUs/>}/>
        <Route path="/delivery" element={<Delivery/>}/>
        <Route path="/contacts" element={<Contacts/>}/>
        <Route path="/admin" element={<AdminPage/>}/>
      </Routes>
      <BlankSpace/>
      <NavBar/>
      <Footer/>
    </div>
  );
}

export default App;