import { Link, useParams } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import { HeaderCorners } from "../../components/Header/Header"

import "../../components/Sections/Sections.css"

import { SectionModel } from "../../components/Sections/Sections"
import macbanner from "../../components/Sections/banners/mac.png"
import macbookair from "../../components/Sections/images/Mac/MacBook Air.png"
import airm3 from "../../components/Sections/images/Mac/Air M3.png"
import airm2 from "../../components/Sections/images/Mac/Air M2.png"
import airm1 from "../../components/Sections/images/Mac/Air M1.png"
import prom3 from "../../components/Sections/images/Mac/Pro M3.png"
import prom2 from "../../components/Sections/images/Mac/Pro M2.png"
import prom1 from "../../components/Sections/images/Mac/Pro M1.png"

import { Item } from "../../components/Item/Item"
import { BackButton } from "../../ui/Buttons"

export function MacModels() {
    const [ids, setIds] = useState([])
    const {model} = useParams()

    useEffect(() => {
        setIds([])
        if (model === "all") {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": "Mac"}).then((response) => {
                setIds(response.data)
            })
        } else {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": model}).then((response) => {
                setIds(response.data)
            })
        }
    }, [model])

    return (
        <div className="section-body">
            <div><BackButton/></div>
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={airm3} title={"MacBook Air 15 M3"} to={"/mac/MacBook Air 15 M3"}/>
                    <SectionModel image={airm3} title={"MacBook Air 13 M3"} to={"/mac/MacBook Air 13 M3"}/>
                    <SectionModel image={airm2} title={"MacBook Air 15 M2"} to={"/mac/MacBook Air 15 M2"}/>
                    <SectionModel image={airm2} title={"MacBook Air 13 M2"} to={"/mac/MacBook Air 13 M2"}/>
                    <SectionModel image={airm1} title={"MacBook Air 13 M1"} to={"/mac/MacBook Air 13 M1"}/>
                    <SectionModel image={prom3} title={"MacBook Pro 16 M3"} to={"/mac/MacBook Pro 16 M3"}/>
                    <SectionModel image={prom3} title={"MacBook Pro 14 M3"} to={"/mac/MacBook Pro 14 M3"}/>
                    <SectionModel image={prom2} title={"MacBook Pro 16 M2"} to={"/mac/MacBook Pro 16 M2"}/>
                    <SectionModel image={prom2} title={"MacBook Pro 14 M2"} to={"/mac/MacBook Pro 14 M2"}/>
                    <SectionModel image={prom1} title={"MacBook Pro 16 M1"} to={"/mac/MacBook Pro 16 M1"}/>
                    <SectionModel image={prom1} title={"MacBook Pro 14 M1"} to={"/mac/MacBook Pro 14 M1"}/>
                </div>
            </div>
            {model === "all" ? <></> : 
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/mac/all">
                    Все Mac
                </Link>
            </div>
            }
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((item, key) => (
                        <Item key={key} id={item["id"]}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

//<div className="section-body-banner">
//<img src={macbanner}/>
//</div>