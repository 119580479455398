import "./order.css";
import { Button, ButtonPrimary } from "../../ui/Buttons";
import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";

const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
`;

const WideButtonSelected = styled(WideButton)`
    color: #c9333a;
    outline: 1px solid #c9333a;
    background-color: #f6f8fa;
    border: 1px solid #c9333a;
`;

const ProceedButton = styled(ButtonPrimary)`
    margin-top: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export function FastOrder() {
    return (
        <div className="order-view-body">
            <div className="order-view-content">
                <OrderWithDelivery />
            </div>
        </div>
    );
}

function OrderWithDelivery() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const allowedPhoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        for (let i = 0; i < phone.length; i++) {
            if (!allowedPhoneValues.includes(phone[i])) {
                setPhone((prevPhone) => prevPhone.replace(phone[i], ""));
            }
        }

        if (phone.length > 10) {
            setPhone((prevPhone) => prevPhone.slice(0, 10));
        }
    }, [phone]);

    useEffect(() => {
        console.log(localStorage.getItem("fastOrder"));
    }, [])

    const placeOrder = () => {
        const orderJson = {
            type: "fast",
            name,
            phone,
            items: [{ id: localStorage.getItem("fastOrder"), amount: 1 }], // Array with one object
        };
        setLoading(true)
        axios.post("https://shopmobilaserver.ru/items/place_order", orderJson)
            .then((response) => {
                console.log(response.data);
                window.location.href = "/cart/order/finished";
            })
            .catch((error) => {
                console.error("Order placement failed:", error);
            });
    };
    

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "400px" }}>
            <div>
                <input placeholder="Имя" value={name} onChange={(e) => setName(e.target.value)} />
                <div className="mixed-input">
                    +7 <input placeholder="Номер телефона" type="tel" className="mixed-field-left" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </div>
            </div>
            { loading ? <ProceedButton>Обработка заказа...</ProceedButton> : 
                <ProceedButton onClick={placeOrder}>Оформить</ProceedButton>
            }
        </div>
    );
}
