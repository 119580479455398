import { useState, useEffect } from "react"
import axios from "axios"
import { Button } from "../../ui/Buttons"
import styled from "styled-components"
import "./cart.css"

const SectionButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #c9333a;
    border: 1px solid #c9333a;
    color: white;
    margin-top: 10px;
    &:hover {
        background-color: #c21724;
        color: white;
    }
`

const ItemViewButton = styled(Button)`
    font-size: 10pt;
`

const WideButton = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    text-align: center;
`

const WideButtonSelected = styled(WideButton)`
    color: #c9333a;
    outline: 1px solid #c9333a;
    background-color: #f6f8fa;
    border: 1px solid #c9333a;
`

export function Cart() {
    const [ cart, setCart ] = useState([])
    const [ total, setTotal ] = useState("0")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        var jsonCart = localStorage.getItem("cart")
        var cartObject = JSON.parse(jsonCart)
        if (cartObject === null) {
            cartObject = []
        }
        setCart(cartObject)
        handleTotal()
    }, [])

    const handleTotal = async () => {
        var cartObject = JSON.parse(localStorage.getItem("cart"))
        if (cartObject === null) {
            cartObject = []
       }
        var totalPrice = 0
        for (var i = 0; i < cartObject.length; i++) {
            await axios.post("https://shopmobilaserver.ru/items/getPrice", {"id": cartObject[i]["id"]}).then((response) => {
                totalPrice = totalPrice + (Number(response.data["response"].replace(" ", "")) * cartObject[i]["amount"])
            })
        }
        setTotal(String(totalPrice))
    }

    const handleDelete = (id) => {
        var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++ ) {
                if (cartObject[i]["id"] != id) {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            setCart(newCartObject)
            handleTotal()
    }

    return (
            <div class="cart-view-body">
                { cart.length > 0 ? 
                <div class="cart-view-content">
                    <div class="cart-view-first-side">
                        {cart.map((index, item) => (
                            <CartItem id={cart[item]["id"]} cartAmount={cart[item]["amount"]} handleDelete={handleDelete} handleTotal={handleTotal}/>
                        ))}
                    </div>
                    <div class="cart-view-second-side">
                        <ProceedSection total={total}/>
                    </div>
                    
                </div> : <EmptyCartView/> }
            </div>
        
    )
}

function EmptyCartView() {
    return (
        <div class="empty-cart-view">
            <div class="empty-cart-view-title">
                В корзине пусто...
            </div>
            <div class="empty-cart-view-text">
                Самое время что-нибудь добавить
            </div>
            <Button to="/">На главную</Button>
        </div>
    )
}

function CartItem({id, handleDelete, cartAmount, handleTotal}) {
    const [ title, setTitle ] = useState("...")
    const [ price, setPrice ] = useState("0")
    const [ image, setImage ] = useState("")  
    const [ amount, setAmount ] = useState(cartAmount)

    useEffect(() => {
        axios.post("https://shopmobilaserver.ru/items/getItem", {"id": id}).then((response) => {
            setTitle(response.data["title"])
            setPrice(response.data["price"])
            setImage(response.data["photos"][0])
        })
    }, [id])

    return (
        <div class="cart-item">
            <img src={"https://shopmobilaserver.ru/items/getImage?image=" + image} onClick={() => {window.location.href = "/item/" + id}}/>
            <div class="cart-item-info">
                <div onClick={() => {window.location.href = "/item/" + id}}>
                    {title}
                </div>
                <div class="cart-item-footer">
                    <div class="cart-item-price">
                    {price} ₽ <div class="cart-item-bonus"></div>
                    </div>
                   
                </div>
                <div class="cart-item-buttons">
                    <Counter amount={amount} id={id} handleTotal={handleTotal}/>
                    <ItemViewButton onClick={() => {handleDelete(id)}}>Удалить</ItemViewButton>
                </div>
            </div>
        </div>
    )
}

function Counter({amount, id, handleTotal}) {
    const [ count, setCount ] = useState(amount)

    useEffect(() => {
        if (count < 1) {
            var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++ ) {
                if (cartObject[i]["id"] != id) {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            window.location.reload()
        } else {
            var newCartItem = {
                "id": id,
                "amount": count
            }
            var jsonCart = localStorage.getItem("cart")
            var cartObject = JSON.parse(jsonCart)
            if (cartObject === null) {
                cartObject = []
            }
            var newCartObject = []
            for (var i = 0; i < cartObject.length; i++) {
                if (cartObject[i]["id"] === newCartItem["id"]) {
                    newCartObject.push(newCartItem)
                } else {
                    newCartObject.push(cartObject[i])
                }
            }
            localStorage.setItem("cart", JSON.stringify(newCartObject))
            handleTotal()
            if (localStorage.getItem("payment") === "invoice") {
                //window.location.reload()
            }
        }
    }, [count])

    return (
        <div class="counter">
            <ItemViewButton onClick={() => {setCount(count - 1)}}>
                -
            </ItemViewButton>
            <div class="counter-number">
                {count}
            </div>
            <ItemViewButton onClick={() => {setCount(count + 1)}}>
                +
            </ItemViewButton>
        </div>
    )
}

function useLocalStorageCart() {
    const [cart, setCart] = useState(() => {
        const storedCart = localStorage.getItem("cart");
        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const storedCart = localStorage.getItem("cart");
            setCart(storedCart ? JSON.parse(storedCart) : []);
        };

        window.addEventListener("storage", handleStorageChange);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return [cart, setCart];
}

function ProceedSection({ total }) {
    const [method, setMethod] = useState("cash");
    const [percentageLabel, setPercentageLabel] = useState("");
    const [cart] = useLocalStorageCart();
    const [cartLength, setCartLength] = useState(localStorage.getItem("cart").length)

    useEffect(() => {
        localStorage.setItem("payment", method);
        updatePercentageLabel(method);
    }, [method]);

    useEffect(() => {
        localStorage.setItem("payment", method);
        updatePercentageLabel(method);
    }, []);

    useEffect(() => {
        calculateTotalWithMethod(method);     
        setCartLength(localStorage.getItem("cart").length)
        console.log(cart.reduce((acc, item) => acc + item.amount, 0))
    }, [localStorage.getItem("cart")]);

    useEffect(() => {
        const paymentData = localStorage.getItem("payment");
        
        // Check if paymentData is not null and is a string
        if (paymentData && typeof paymentData === "string") {
          setMethod(paymentData);
        } else {
          console.log("No valid payment data found in localStorage.");
        }
      }, []);

    const updatePercentageLabel = (method) => {
        switch (method) {
            case "qr":
                setPercentageLabel("+6% к цене");
                break;
            case "card":
                setPercentageLabel("+7% к цене");
                break;
            case "invoice":
                const itemCount = cart.reduce((acc, item) => acc + item.amount, 0);
                setPercentageLabel(`+${itemCount < 3 ? "10%" : "15%"} к цене`);
                break;
            case "credit":
                setPercentageLabel("+10% к цене +% банка");
                break;
            case "installment6":
                setPercentageLabel("+20% к цене");
                break;
            case "installment12":
                setPercentageLabel("+30% к цене");
                break;
            default:
                setPercentageLabel(""); // No adjustment for cash
        }
    };

    const calculateTotalWithMethod = (amount) => {
        const baseAmount = Number(amount.replace(" ", ""));
        switch (method) {
            case "qr":
                return (baseAmount * 1.06).toFixed(2);
            case "card":
                return (baseAmount * 1.07).toFixed(2);
            case "invoice":
                const itemCount = cart.reduce((acc, item) => acc + item.amount, 0);
                const rate = itemCount < 3 ? 1.10 : 1.15;
                return (baseAmount * rate).toFixed(2);
            case "credit":
                return (baseAmount * 1.10).toFixed(2);
            case "installment6":
                return (baseAmount * 1.20).toFixed(2);
            case "installment12":
                return (baseAmount * 1.30).toFixed(2);
            default:
                return baseAmount.toFixed(2);
        }
    };

    const handleProceed = async () => {
        const cartObject = JSON.parse(localStorage.getItem("cart"));
        const allItems = [];
        let totalPrice = 0;
        let totalBonus = 0;

        for (let i = 0; i < cartObject.length; i++) {
            await axios.post("https://shopmobilaserver.ru/items/getPrice", { id: cartObject[i].id }).then((response) => {
                const price = response.data.response;
                const bonus = parseInt(Number(price.replace(" ", "")) / 300);

                const thisJson = {
                    id: cartObject[i].id,
                    price: price,
                    amount: cartObject[i].amount,
                    bonus: String(bonus),
                };

                totalPrice += Number(price.replace(" ", ""));
                totalBonus += bonus;

                allItems.push(thisJson);
            });
        }

        localStorage.setItem("orderItems", JSON.stringify(allItems));
        window.location.href = "/cart/order";
    };

    return (
        <div className="item-view-section-container">
            <div className="item-view-price-row">
                <>{calculateTotalWithMethod(total)} ₽</>
            </div>
            <div className="item-view-card-price">
                <div>{percentageLabel}</div>
            </div>
            <div className="transfer-warning">
                Цена за наличные
                <br/><br/>
                Выберите способ оплаты на следующей странице
            </div>
            <SectionButton onClick={handleProceed}>
                Продолжить
            </SectionButton>
        </div>
    );
}
