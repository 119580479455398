import "./info.css"

export function AboutUs() {
    return (
        <div className="info-body">
            <div className="info-content">
                <div className="info-title">О нас</div>
                    Мы занимаемся продажей смартфонов, планшетов, умных часов и другой электроники. У нас Вы всегда найдете оригинальную продукцию ведущих производителей электроники (Apple, Samsung,  и многих других брендов), а также первыми получите эксклюзивные новинки.
                    <br/><br/>
                    В интернет-магазине ShopMobila вы купите смартфон, планшет и другую электронику намного дешевле, чем в торговых сетях и розничных магазинах. Секрет низкой цены — у нас нет затрат на аренду торговых мест, зарплату персонала торговой точки и прочее. Это существенно снижает себестоимость, а значит и конечную цену в ShopMobila.
            </div>
        </div>
    )
}