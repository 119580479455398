import { Link, useParams } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import { HeaderCorners } from "../../components/Header/Header"

import "../../components/Sections/Sections.css"

import { SectionModel } from "../../components/Sections/Sections"
import airpodsbanner from "../../components/Sections/banners/airpods.png"

import { Item } from "../../components/Item/Item"
import { BackButton } from "../../ui/Buttons"

export function AirPodsModels() {
    const [ids, setIds] = useState([])
    const {model} = useParams()

    useEffect(() => {
        setIds([])
        if (model === "all") {
            axios.post("https://shopmobilaserver.ru/items/getDevice", {"device": "AirPods"}).then((response) => {
                setIds(response.data)
            })
        }
    }, [model])

    return (
        <div className="section-body">
            <div><BackButton/></div>
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((item, key) => (
                        <Item key={key} id={item["id"]}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

//<div className="section-body-banner">
//<img src={airpodsbanner}/>
//</div>