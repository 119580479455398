import "./Sections.css"

import iphonebanner from "./banners/iphone.png"
import iphone16promax from "./images/iPhone/16 Pro Max.png"
import iphone16pro from "./images/iPhone/16 Pro.png"
import iphone16plus from "./images/iPhone/16 Plus.png"
import iphone16 from "./images/iPhone/16.png"
import iphone15promax from "./images/iPhone/15 Pro Max.png"
import iphone15pro from "./images/iPhone/15 Pro.png"
import iphone15plus from "./images/iPhone/15 Plus.png"
import iphone15 from "./images/iPhone/15.png"
import iphone14 from "./images/iPhone/14.png"
import iphone13 from "./images/iPhone/13.png"
import iphone12 from "./images/iPhone/12.png"
import iphone11 from "./images/iPhone/11.png"

import airpodsbanner from "./banners/airpods.png"
import airpodspro2 from "./images/AirPods/Pro 2.png"
import airpodsmax from "./images/AirPods/Max.png"
import airpods3 from "./images/AirPods/3.png"
import airpods2 from "./images/AirPods/2.png"

import ipadbanner from "./banners/ipad.png"
import ipadpro from "./images/iPad/Pro.png"
import ipadair from "./images/iPad/Air.png"
import ipad9 from "./images/iPad/9.png"
import ipad10 from "./images/iPad/10.png"
import ipadairm2 from "./images/iPad/Air M2.png"
import prom1 from "./images/iPad/Pro M1.png"
import prom2 from "./images/iPad/Pro M2.png"

import { Item } from "../Item/Item"

import axios from "axios"
import { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { BackButton } from "../../ui/Buttons"

export function IphoneSection() {
    const [ids, setIds] = useState([])

    useEffect(() => {
        console.log("aaasdasd")
        axios.post("https://shopmobilaserver.ru/items/getShowcase", {"location": "iphone"}).then((response) => {
            setIds(response.data["items"])
        })
    }, [])

    return (
        <div className="section-body">
            
            <div className="section-body-banner">
                <img src={iphonebanner} style={{backgroundColor: "#f1f1f1"}}/>
            </div>
            
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={iphone16promax} title={"iPhone 16 Pro Max"} to={"/iphone/16 Pro Max"}/>
                    <SectionModel image={iphone16pro} title={"iPhone 16 Pro"} to={"/iphone/16 Pro"}/>
                    <SectionModel image={iphone16plus} title={"iPhone 16 Plus"} to={"/iphone/16 Plus"}/>
                    <SectionModel image={iphone16} title={"iPhone 16"} to={"/iphone/16"}/>
                    <SectionModel image={iphone15promax} title={"iPhone 15 Pro Max"} to={"/iphone/15 Pro Max"}/>
                    <SectionModel image={iphone15pro} title={"iPhone 15 Pro"} to={"/iphone/15 Pro"}/>
                    <SectionModel image={iphone15plus} title={"iPhone 15 Plus"} to={"/iphone/15 Plus"}/>
                    <SectionModel image={iphone15} title={"iPhone 15"} to={"/iphone/15"}/>
                    <SectionModel image={iphone14} title={"iPhone 14"} to={"/iphone/14"}/>
                    <SectionModel image={iphone13} title={"iPhone 13"} to={"/iphone/13"}/>
                    <SectionModel image={iphone12} title={"iPhone 12"} to={"/iphone/12"}/>
                    <SectionModel image={iphone11} title={"iPhone 11"} to={"/iphone/11"}/>
                </div>
            </div>
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/iphone/all">
                    Все iPhone
                </Link>
            </div>
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((id) => (
                        <Item key={id} id={id}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export function AirpodsSection() {
    const [ids, setIds] = useState([])

    useEffect(() => {
        console.log("aaasdasd")
        axios.post("https://shopmobilaserver.ru/items/getShowcase", {"location": "airpods"}).then((response) => {
            setIds(response.data["items"])
        })
    }, [])

    return (
        <div className="section-body">
            <div className="section-body-banner">
                <img src={airpodsbanner} style={{backgroundColor: "white"}}/>
            </div>
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/airpods/all">
                    Все AirPods
                </Link>
            </div>
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((id) => (
                        <Item key={id} id={id}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export function IpadSection() {
    const [ids, setIds] = useState([])

    useEffect(() => {
        console.log("aaasdasd")
        axios.post("https://shopmobilaserver.ru/items/getShowcase", {"location": "ipad"}).then((response) => {
            setIds(response.data["items"])
        })
    }, [])

    return (
        <div className="section-body">
            <div className="section-body-banner">
                <img src={ipadbanner} style={{backgroundColor: "#f1f1f1"}}/>
            </div>
            <div className="section-models-scroll">
                <div className="section-models">
                    <SectionModel image={ipadpro} title={"iPad Pro 13 M4"} to={"/ipad/Pro 13 M4"}/>
                    <SectionModel image={ipadpro} title={"iPad Pro 11 M4"} to={"/ipad/Pro 11 M4"}/>
                    <SectionModel image={prom2} title={"iPad Pro 12.9 M2"} to={"/ipad/Pro 12.9 M2"}/>
                    <SectionModel image={prom2} title={"iPad Pro 11 M2"} to={"/ipad/Pro 11 M2"}/>
                    <SectionModel image={prom1} title={"iPad Pro 12.9 M1"} to={"/ipad/Pro 12.9 M1"}/>
                    <SectionModel image={prom1} title={"iPad Pro 11 M1"} to={"/ipad/Pro 11 M1"}/>
                    <SectionModel image={ipadairm2} title={"iPad Air 13 M2"} to={"/ipad/Air 13 M2"}/>
                    <SectionModel image={ipadairm2} title={"iPad Air 11 M2"} to={"/ipad/Air 11 M2"}/>
                    <SectionModel image={ipadair} title={"iPad Air 10.9 M1"} to={"/ipad/Air 10.9 M1"}/>
                    <SectionModel image={ipad10} title={"iPad 10"} to={"/ipad/10"}/>
                    <SectionModel image={ipad9} title={"iPad 9"} to={"/ipad/9"}/>
                </div>
            </div>
            <div className="go-to-section-button-holder">
                <Link className="go-to-section-button" to="/ipad/all">
                    Все iPad
                </Link>
            </div>
            <div className="section-content-body">
                <div className="section-content">
                    {ids.map((id) => (
                        <Item key={id} id={id}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export function SectionModel({image, title, to}) {
    return (
        <Link className="section-model-body" to={to}>
            <img src={image}/>
            <div className="section-model-title">
                {title}
            </div>
        </Link>
    )
}

function SectionModelInv({image, title, to}) {
    return (
        <Link className="section-model-body section-model-body-inv">
            <img src={image}/>
            <div className="section-model-title">
                {title}
            </div>
        </Link>
    )
}