import "./itemView.css"
import { useLocation, useParams } from "react-router-dom"

import { useEffect, useState } from "react"
import axios from "axios"

import { Swiper } from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import Popup from "reactjs-popup"
import styled from "styled-components"

import { BackButton, Button, ButtonPrimary } from "../../ui/Buttons"

const PrimaryA = styled.a`
    background-color: #e11134;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    text-align: center;
    opacity: 1.0;
    transition: scale 0.1s;
    @media(hover: hover) {
        &:hover {
            opacity: 0.9;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
`


//<div class="swiper-pagination"></div>

export function ItemView() {
    const location = useLocation()
    const [ showPopup, setShowPopup ] = useState(false)
    const {id} = useParams()
    const [images, setImages] = useState([])
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState("")
    const [availability, setAvailability] = useState("available")
    const [specs, setSpecs] = useState({})
    const [specKeys, setSpecKeys] = useState([])
    const [description, setDescription] = useState("")
    const [showAllSpecs, setShowAllSpecs] = useState(false)
    const [showFullDescription, setShowFullDescription] = useState(false);
 
    useEffect(() => {
        const swiper = new Swiper(".item-swiper", {
            slidesPerView: 1,
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            } 
        })
    }, [images.length]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        console.log(id)
        axios.post("https://shopmobilaserver.ru/items/getItem", {"id": String(id)}).then((response) => {
            console.log(response.data)
            setImages(response.data["photos"])
            setTitle(response.data["title"])
            setPrice(response.data["price"])
            setAvailability(response.data["availability"])
            axios.post("https://shopmobilaserver.ru/items/getSpecs", {"model": response.data["model"]}).then((specResp) => {
                setSpecs(specResp.data["specs"])
                setSpecKeys(Object.keys(specResp.data["specs"]))
            })
            axios.post("https://shopmobilaserver.ru/items/getDesc", {"model": response.data["model"]}).then((specResp) => {
                setDescription(specResp.data["description"])
            })
        })
    }, [id])

    const handleToCart = () => {
        setShowPopup(true)
        AddToCart(id)
    }

    useEffect(() => {
        localStorage.setItem("fastOrder", id)
    }, [id]) 

    return (
        <>
        <div><BackButton/></div>
       
        <div className="itemView-body">
            
            <div className="itemView-content">
                <div className="itemView-side itemView-images">
                    { images.length !== 0 ? 
                    <div className="item-swiper">
                        <div className="swiper-wrapper">
                            {images.map((img) => (
                                <div className="swiper-slide">
                                    <div className="img-wrapper">
                                        <img src={"https://shopmobilaserver.ru/items/getImage?image="+img}/>
                                    </div>
                                </div>
                            ))
                            }   
                        </div>
                        
                        <div class="swiper-button-prev"><i class="fa-solid fa-chevron-left swiper-btn"></i></div>
                        <div class="swiper-button-next"><i class="fa-solid fa-chevron-right swiper-btn"></i></div>
                    </div>
                    : <></>}
                </div>
                <div className="itemView-side item-info-side">
                    <div className="item-info-section">
                        <div className="item-view-title">
                            {title}
                        </div>
                        <div className="item-price-section">
                            <div className="item-section-price">
                                {price} ₽
                            </div>
                            <div className="item-section-availability">
                                { availability === "available" ?  <div className="item-availability item-available"><i class="fa-solid fa-check"></i> В наличии</div>  
                                : <div>{ availability === "unavailable" ? <div className="item-availability item-unavailable"><i class="fa-solid fa-xmark"></i> Нет в наличии</div> 
                                : <div className="item-availability item-delivery"><i class="fa-solid fa-truck"></i> Под заказ</div> }</div>}
                            </div>
                        </div>
                        <span/>
                        <ButtonPrimary onClick={handleToCart}>В корзину</ButtonPrimary>
                        <ButtonPrimary style={{marginTop: "10px"}} onClick={() => {window.location.href = "/fastOrder"}}>Купить в 2 клика</ButtonPrimary>
                    </div>
                    <Popup open={showPopup} modal>
                        <div class="cart-popup">
                            <div class="item-section-title">
                                Добавлено в корзину
                            </div>
                            <span/>
                            <ButtonPrimary onClick={() => {setShowPopup(false)}}>
                                Вернуться к покупкам
                            </ButtonPrimary>
                            <span/>
                            <ButtonPrimary onClick={() => {window.location.href = "/cart"}}>
                                Продолжить оформление заказа
                            </ButtonPrimary>
                        </div>
                    </Popup>
                    {specKeys.length > 0 ? 
                        <div className="item-info-section">
                            <div className="section-info-title">Характеристики</div>
                            {specKeys.slice(0, showAllSpecs ? specKeys.length : 4).map((key) => (
                                <div className="spec-record" key={key}>
                                    <div className="spec-record-title">{key}</div>
                                    <div className="spec-record-value">{specs[key]}</div>
                                </div>
                            ))}
                            {specKeys.length > 4 && (
                                <ButtonPrimary style={{marginTop: "10px"}} onClick={() => setShowAllSpecs(!showAllSpecs)}>
                                    {showAllSpecs ? "Скрыть" : "Показать все"}
                                </ButtonPrimary>
                            )}
                        </div>
                    : <></>}
                    { description.length > 0 ? 
                    <div className="item-info-section">
                        <div className="section-info-title">Описание</div>
                        {showFullDescription ? description : `${description.slice(0, 100)}...`}
                        {description.length > 100 && (
                            <ButtonPrimary style={{marginTop: "10px"}} onClick={() => setShowFullDescription(!showFullDescription)}>
                                {showFullDescription ? "Скрыть" : "Показать все"}
                            </ButtonPrimary>
                        )}
                    </div> 
                    : <></> }
                    <div className="item-info-section">
                        <div className="section-info-title">
                            Доставка
                        </div>
                        <div>
                            <div className="delivery-option">
                                1. Доставка в пределах мкад 
                            </div>
                            <div className="delivery-option-value">
                                Цена: 700р (Оформление до 16:00)
                                <br/>После 16:00 от 800р
                            </div>
                            <div className="delivery-option">
                                2. Доставка за мкад (каждый км +50р)
                            </div>
                            <div className="delivery-option-value">
                                Цена: 700р (Оформление до 16:00)
                                <br/>После 16:00 от 800р
                            </div>
                            <div className="delivery-option">
                                3. Доставка к определенному времени
                            </div>
                            <div className="delivery-option-value">
                                От 800р 
                            </div>
                            <div className="delivery-option">
                                4. Доставка по России
                            </div>
                            <div className="delivery-option-value">
                                Осуществляется курьерской службой СДЭК и возможна только после 100% оплаты заказа и доставки. Стоимость доставки рассчитывается при оформлении заказа. Подробности уточняйте у наших операторов.
                            </div>
                        </div>
                    </div>
                    <div className="item-info-section">
                        <div className="section-info-title">
                            Способы оплаты
                        </div>
                        <div>
                            <div className="delivery-option">
                                1. Картой по терминалу 
                            </div>
                            <div className="delivery-option-value">
                                +7% к цене 💳
                            </div>
                            <div className="delivery-option">
                                2. СБП
                            </div>
                            <div className="delivery-option-value">
                                +6% к цене 💳
                            </div>
                            <div className="delivery-option">
                                3. ⁠В Кредит
                            </div>
                            <div className="delivery-option-value">
                                +10% к цене + % банка 🏦
                            </div>
                            <div className="delivery-option">
                                4. В Рассрочку на 6 месяцев
                            </div>
                            <div className="delivery-option-value">
                                +20% 🏦
                            </div>
                            <div className="delivery-option">
                                5. В Рассрочку на 12 месяцев
                            </div>
                            <div className="delivery-option-value">
                                +30% 🏦
                            </div>
                            <div className="delivery-option">
                                6. Безналичный расчёт (до 2-х устройств)
                            </div>
                            <div className="delivery-option-value">
                                +10% к цене Без НДС 💼
                            </div>
                            <div className="delivery-option">
                                6. Безналичный расчёт (от 2-х устройств)
                            </div>
                            <div className="delivery-option-value">
                                +15% к цене Без НДС 💼
                            </div>
                        </div>
                    </div>
                    <div className="item-info-section">
                        <div className="section-info-title">
                            Связаться с нами
                        </div>
                        <div className="call-us-text">
                            Позвоните, и мы поможем Вам с выбором
                        </div>
                        <PrimaryA href="tel:+79264423636" class="item-call-button-element">Позвонить</PrimaryA>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

function AddToCart(id) {
    var currentCart = JSON.parse(localStorage.getItem("cart"))
    if (currentCart === null) {
        currentCart = []
        currentCart.push({
            "id":  id,
            "amount": 1
        })
    } else {
        var cartIds = []
        for (var i = 0; i < currentCart.length; i++) {
            if (typeof currentCart[i] !== Object) {
                localStorage.removeItem("cart")
            }
            cartIds.push(currentCart[i]["id"])
        }
        if (cartIds.includes(id)) {
            console.log("aaaa")
            for (var i = 0; i < currentCart.length; i++) {
                if (currentCart[i]["id"] === id) {
                    currentCart[i] = {
                        "id": id,
                        "amount": currentCart[i]["amount"] + 1
                    }
                }
            }
        } else {
            currentCart.push({
                "id":  id,
                "amount": 1
            })
        }
    }
    localStorage.setItem("cart", JSON.stringify(currentCart))
    console.log(currentCart)
}