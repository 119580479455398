import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export const ButtonPrimary = styled(Link)`
    background-color: #e11134;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    text-align: center;
    opacity: 1.0;
    transition: scale 0.1s;
    @media(hover: hover) {
        &:hover {
            opacity: 0.9;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
`

export const Button = styled(Link)`
    background-color: #F1F1F1;
    outline: 1px solid #E3E3E3;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    border-radius: 20px;
    transition: scale 0.1s;
    @media(hover: hover) {
        &:hover {
            background-color: #E3E3E3;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
`

export const ButtonInline = styled(Link)`
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    border-radius: 20px;
    margin-left: 10px;
    opacity: 0.9;
    transition: scale 0.1s;
    @media(hover: hover) {
        &:hover {
            opacity: 1.0;
        }
    }
    &:active {
        scale: 0.9;
    };
    user-select: none;
    -webkit-user-select: none;
`

export const ButtonHolder = styled.div`
    display: flex;
    margin-top: 10px;
`

const BackButtonBody = styled.a`
    margin: 10px 0px 0px 0px;
    display: flex;
    justify-content: center;
`

const BackButtonContainer = styled.a`
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding-left: 10px;
`

const BackButtonItem = styled.a`
    cursor: pointer;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    display: flex;
    padding: 7px 12px;
    border-radius: 15px;
    transition: scale 0.1s;
    user-selection: none;
    text-decoration: none;
    color: black;
    @media(min-width: 600px) {
        &:hover {
            background-color: #f3f4f6;
        }
        &:active {
            scale: 0.9;
        }
    }
    @media(max-width: 600px) {
        &:active {
            background-color: #f3f4f6;
            scale: 0.9;
        }
    }
`

export function BackButton() {
    const [show, setShow] = useState(false)

    useEffect(() => {
        console.log(window.history.length)
        if (window.history.length <= 2) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [])

    return (
        <>
        { show === true ?
            <BackButtonBody>
                <BackButtonContainer>
                    <BackButtonItem href="javascript:window.history.back()">
                        <i class="fa-solid fa-chevron-left"></i><span/> Назад
                    </BackButtonItem>
                </BackButtonContainer>
            </BackButtonBody>
            : <></>
        }
        </>
    )
}