import "./info.css"

export function Contacts() {
    return (
        <div className="info-body">
            <div className="info-content">
                <div className="info-title">Связаться с нами</div>
                Мы находимся по адресу: г.Москва ул.Барклая д.8 2-этаж 267 павильон.
                <br/><br/>
                Телефон: +79264423636
                <br/><br/>
                Режим работы: каждый день с 11:00 до 20:00
            </div>
        </div>
    )
}