import "./Item.css"

import test from "./test.webp"
import axios from "axios"
import { useEffect, useState } from "react"

import { Button } from "../../ui/Buttons"

import { Link } from "react-router-dom"

export function Item({id}) {
    const [title, setTitle] = useState("")
    const [price, setPrice] = useState("")
    const [image, setImage] = useState("") 
    const [availability, setavailability] = useState("")

    useEffect(() => {
        axios.post("https://shopmobilaserver.ru/items/get", {"id": id}).then((response) => {

            setTitle(response.data["title"])
            setPrice(response.data["price"])
            setImage("https://shopmobilaserver.ru/items/getImage?image=" + response.data["photos"][0])
            setavailability(response.data["availability"])

            console.log(image)
        })
    }, [])

    return (
        <div className="item-body-outer">
            <Link className="item-body" to={"/item/"+String(id)}>
                <div className="item-body-inner">
                    <div className="image-preview">
                        <img src={image}/>
                    </div>
                    <div className="item-title">
                        {title}
                    </div>
                    <div className="item-info">
                        <div className="item-price">
                            {price} ₽
                        </div>
                        
                            { availability === "available" ?  <div className="item-availability item-available"><i class="fa-solid fa-check"></i> В наличии</div>  
                            : <div>{ availability === "unavailable" ? <div className="item-availability item-unavailable"><i class="fa-solid fa-xmark"></i> Нет в наличии</div> 
                            : <div className="item-availability item-delivery"><i class="fa-solid fa-truck"></i> Под заказ</div> }</div>}
                    </div>
                    
                </div>
                <Link className="item-show-button" to={"/item/"+String(id)}>
                    Перейти
                </Link>
            </Link>
        </div>
    )
}